import { styled } from 'stitches.config';
import { StyledChips } from './StyledChips';

type PropType = {
  isChecked: boolean;
  onCheck: React.MouseEventHandler<HTMLButtonElement>;
  text: string;
  disabled?: boolean;
};

function Chips({ isChecked, onCheck, text, disabled }: PropType) {
  return (
    <ChipsWrapper onClick={onCheck} isChecked={isChecked} disabled={disabled}>
      {text}
    </ChipsWrapper>
  );
}

const ChipsWrapper = styled('button', StyledChips);

export default Chips;
