import { timings, animation } from '../../Theme/Settings/animation';

export const StyledChips = {
  display: 'inline-flex',
  justifyContent: 'center',
  border: '1px solid',
  borderColor: 'transparent',
  minWidth: 56,
  lineHeight: '$lh133',
  ls: '$ls1',
  fs: 6,
  backgroundColor: '$primary2',
  width: 'fit-content',
  color: '$filterChipsColor',
  py: 3,
  px: 4,
  '&:hover': {
    backgroundColor: '$primary1',
  },
  cursor: 'pointer',
  transition: `all ${timings.oneFifth} ${animation.timingFn}`,
  variants: {
    disabled: {
      true: {
        backgroundColor: '$primary4',
        color: '$primaryLight3',
        pointerEvents: 'none',
      },
    },
    isChecked: {
      true: {
        borderColor: '$JE68GreenPrimary',
      },
    },
  },
};
