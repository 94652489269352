import { styled } from 'stitches.config';
import { ProductType } from 'Enums/ProductType.enum';
import ProductPageModel from 'Models/Pages/ProductPage/ProductPageModel.interface';
import Attributes from 'Molecules/Attributes/Attributes';

type ProductTitleType = {
  productPage: ProductPageModel;
  used?: string;
};

function ProductTitle({ productPage, used }: ProductTitleType) {
  const {
    isUsed,
    brand,
    pageHeading,
    parentCategoryName,
    manufacturerYear,
    productType,
  } = productPage;

  const attributes = [
    {
      value: isUsed ? used : '',
    },
    {
      value:
        productType === ProductType.MOTORCYCLES &&
        manufacturerYear &&
        manufacturerYear,
    },
    {
      value: !isUsed && parentCategoryName && parentCategoryName,
    },
    {
      value: !isUsed && brand && brand,
    },
  ];

  return (
    <>
      <Attributes attributeList={attributes} />
      <ProductDisplayName>{pageHeading}</ProductDisplayName>
    </>
  );
}

const ProductDisplayName = styled('h1', {
  fs: 16,
  lineHeight: '$lh1125',
  fontFamily: '$fontSecondary600',
  mb: 1,
  mt: 0,
  textTransform: 'uppercase',
  color: '$textPrimary',
});

export default ProductTitle;
