import CollapsableSection from 'Molecules/CollapsableSection/CollapsableSection';
import { styled } from 'stitches.config';
import XHtmlArea from 'Molecules/XHtmlArea/XHtmlArea';

type ProductDescriptionType = {
  extendedDescription?: string;
  descriptionTitle: string;
};

function ProductDescription({
  extendedDescription,
  descriptionTitle,
}: ProductDescriptionType) {
  if (!extendedDescription) {
    return <></>;
  }

  return (
    <CollapsableSection title={descriptionTitle} fullWidth openByDefault>
      <XHtmlArea content={extendedDescription} />
    </CollapsableSection>
  );
}

export default styled(ProductDescription);
