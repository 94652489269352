import { useState, useEffect, useRef, useMemo } from 'react';
import Button from 'Atoms/Buttons/Button';
import { styled } from 'stitches.config';
import { ProductType } from '../../Enums/ProductType.enum';
import { Variant } from '../../Enums/Variant.enum';
import VariationModel from 'Models/KexVariation/VariationModel.interface';
import { useVariantData } from 'Shared/Providers/VariantProvider';
import QuantitySelector from '../../Atoms/Quantity/QuantitySelector';
import { GetCart, SetQuantity } from 'Shared/Cart/Cart';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import { useKexNavigate } from 'Kex/KexRouter/KexRouter';
import TestDriveModal from 'Organisms/TestDriveModal/TestDriveModal';
import SubmitInterestModal from 'Organisms/SubmitInterestModal/SubmitInterestModal';
import ContactModal from 'Organisms/ContactModal/ContactModal';
import Error from 'Atoms/Error/Error';
import ProductTranslations from '../../Models/Translations/ProductTranslations.interface';
import useOutsideClick from 'Shared/Hooks/useOutsideClick';
import { useUserStateData } from '../../Shared/Providers/UserContextProvider';
import {
  getBikeForLoggedOutUser,
  getSelectedModel,
} from '../../Shared/Common/Helpers';

type ProductCTAType = {
  variationCollection: VariationModel[];
  productLabels: ProductTranslations;
  isUsed: boolean;
  productType: string;
  productCode: string;
};

function ProductCTA({
  variationCollection,
  productLabels,
  isUsed,
  productType,
  productCode,
}: ProductCTAType) {
  const [quantity, setQuantity] = useState<number>(1);
  const [isShowQuantity, setIsShowQuantity] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBuyDisabled, setIsBuyDisabled] = useState<boolean>(false);
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  const [showTestDriveModal, setShowTestDriveModal] = useState<boolean>(false);
  const [showSubmitInterestModal, setShowSubmitInterestModal] =
    useState<boolean>(false);
  const [isAddToCartError, setAddToCartError] = useState<boolean>(false);

  const errorCartRef = useRef<HTMLSpanElement>(null);
  useOutsideClick(errorCartRef, () => setAddToCartError(false));

  const selectedModel = getSelectedModel();

  const {
    user: { primaryBike },
    miniCartDispatch,
    miniCartIsOpen,
  } = useUserStateData();

  const bikeId = useMemo(() => {
    if (primaryBike) {
      if (primaryBike.modelId === selectedModel) return primaryBike.name;
      else return undefined;
    }

    const sessionBike = getBikeForLoggedOutUser();

    if (sessionBike && sessionBike.model === selectedModel) {
      return `${sessionBike.brand} ${sessionBike.modelName} ${sessionBike.year}`;
    }

    return undefined;
  }, [primaryBike, selectedModel]);

  const {
    testDrive,
    contactUs,
    configureAndOrder,
    order,
    errorSelectSize,
    submitOfInterest,
  } = productLabels;

  const {
    languageRoute,
    staticPages: { motorcycleConfiguratorPage },
  } = useAppSettingsData();

  const { hasMotorCycle } = GetCart(languageRoute);
  const { state, variantDispatch } = useVariantData();
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);

  const kexNavigate = useKexNavigate();

  useEffect(() => {
    const hasBuyables = variationCollection
      .filter(
        (variant) => variant.color?.key === state.selectedVariant?.color?.key
      )
      .some((variant) => variant.isBuyable);

    setIsBuyDisabled(!hasBuyables);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedVariant]);

  useEffect(() => {
    setQuantity(1);
  }, [state.selectedVariant]);

  function handleAddToCart(
    code: string,
    quantity: number,
    productType: string
  ) {
    // user must select size before proceeding to add cart
    if (state.hasSizes && !state.hasSelectedSize) {
      variantDispatch({
        type: Variant.SET_ADD_CART_ERROR,
        errorSize: true,
      });
      return;
    }
    miniCartDispatch(miniCartIsOpen ? 'closeMiniCart' : 'mountAndOpen');
    // allow max 1 motorcycle in cart
    if (productType === ProductType.MOTORCYCLES) {
      if (hasMotorCycle) {
        setAddToCartError(true);
        return;
      }
    }

    setIsLoading(true);

    SetQuantity(code, quantity, languageRoute, bikeId)
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));

    variantDispatch({
      type: Variant.SET_ADD_CART_ERROR,
      errorSize: false,
    });
  }

  const handleConfigurationClick = (variantCode: string) => {
    //Redirect user to motorcycle configurator page
    kexNavigate(
      `${motorcycleConfiguratorPage}?productcode=${productCode}&variantcode=${variantCode}`
    );
  };

  const isSoonInStock = state.selectedVariant?.stock?.salesStartFormatted;

  const variant = state.selectedVariant;

  const RenderMotorcycleCTA = () => {
    return (
      <>
        <ProductCTAItemMain>
          {!isSoonInStock && (
            <>
              {isUsed ? (
                <Button
                  type="primary"
                  fullWidth
                  onClick={() =>
                    variant &&
                    handleAddToCart(variant.code, quantity, variant.productType)
                  }
                  isLoading={isLoading}
                  disabled={isBuyDisabled}
                >
                  {order}
                </Button>
              ) : (
                <Button
                  type="primary"
                  fullWidth
                  onClick={() =>
                    variant && handleConfigurationClick(variant.code)
                  }
                  disabled={isBuyDisabled}
                >
                  {configureAndOrder}
                </Button>
              )}
            </>
          )}
        </ProductCTAItemMain>
        <ProductCTAItem>
          <Button
            type="secondaryDark"
            fullWidth
            onClick={() => setShowContactModal(true)}
          >
            {contactUs}
          </Button>
          <ContactModal
            showModal={showContactModal}
            setShowModal={setShowContactModal}
          />
        </ProductCTAItem>
        {isSoonInStock ? (
          <ProductCTAItem>
            <Button fullWidth onClick={() => setShowSubmitInterestModal(true)}>
              {submitOfInterest}
            </Button>
            <SubmitInterestModal
              showModal={showSubmitInterestModal}
              setShowModal={setShowSubmitInterestModal}
            />
          </ProductCTAItem>
        ) : (
          <ProductCTAItem>
            <Button
              type="secondaryDark"
              fullWidth
              onClick={() => setShowTestDriveModal(true)}
            >
              {testDrive}
            </Button>
            <TestDriveModal
              showModal={showTestDriveModal}
              setShowModal={setShowTestDriveModal}
            />
          </ProductCTAItem>
        )}
      </>
    );
  };

  const RenderOtherCTA = () => {
    return (
      <ProductCTAItemMain>
        <ProductClothing>
          {(isShowQuantity || isDesktop) && variant ? (
            <QuantitySelector
              code={variant.code}
              maxQuantity={variant.stock.stockQuantity}
              minQuantity={1}
              currentQuantity={quantity}
              handleIncrement={() => setQuantity(quantity + 1)}
              handleDecrement={() => setQuantity(quantity - 1)}
              size="l"
              disabled={isBuyDisabled}
            />
          ) : (
            <CurrentQuantityWrapper onClick={() => setIsShowQuantity(true)}>
              {quantity}
            </CurrentQuantityWrapper>
          )}

          <Button
            type="primary"
            fullWidth
            onClick={() =>
              variant &&
              handleAddToCart(variant.code, quantity, variant.productType)
            }
            isLoading={isLoading}
            disabled={isBuyDisabled}
          >
            {order}
          </Button>
        </ProductClothing>
      </ProductCTAItemMain>
    );
  };

  return (
    <>
      <ProductCTAContainer>
        <ProductCTAWrapper>
          {productType === ProductType.MOTORCYCLES
            ? RenderMotorcycleCTA()
            : RenderOtherCTA()}
        </ProductCTAWrapper>
      </ProductCTAContainer>

      {state.errorSize && <Error errorLabel={errorSelectSize} />}

      {isAddToCartError && (
        <Error
          errorLabel={productLabels.motorcycleAlreadyInCartError}
          ref={errorCartRef}
        />
      )}
    </>
  );
}

const ProductCTAContainer = styled('div', {
  display: 'flex',
  width: '100%',
});

const ProductCTAWrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
  width: '100%',
  gap: 16,
  mb: 4,
});

const ProductCTAItem = styled('div', {
  gridColumnEnd: 'span 6',
  gridColumnStart: 'auto',
});

const ProductCTAItemMain = styled('div', {
  gridColumnEnd: 'span 12',
  gridColumnStart: 'auto',
});

const ProductClothing = styled('div', {
  display: 'flex',
  g: 1,
});

const CurrentQuantityWrapper = styled('button', {
  backgroundColor: '$quantityBackground',
  p: 2,
  color: '$secondary2',
  lineHeight: '$lh125',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'default',
  w: 12,
});

export default ProductCTA;
