import { useState } from 'react';
import useMedia from 'Shared/Hooks/useMedia';
import { styled } from 'stitches.config';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import Carousel from '../../Organisms/Carousel/Carousel';
import FullScreenModal from '../../Organisms/Modal/FullScreenModal';
import BlurredImage from './BlurredImage';
import { useVariantData } from 'Shared/Providers/VariantProvider';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import ProductPageModel from 'Models/Pages/ProductPage/ProductPageModel.interface';
import ImageModel from '../../Models/Assets/ImageModel.interface';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { isEmpty } from 'Shared/Common/Helpers';

function ProductImage() {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);
  const { missingImage } = useAppSettingsData();

  const { isUsed } = useCurrentPage<ProductPageModel>();
  const { state } = useVariantData();

  if (!state?.selectedVariant?.variationImages) return <></>;

  const variationImages = state.selectedVariant?.variationImages;

  if (isEmpty(variationImages)) {
    const missingSrc = `${missingImage.src}?preset=product-page-desktop-secondary`;

    return (
      <BlurredImage
        key={0}
        index={0}
        lowQualitySrc={missingSrc}
        highQualitySrc={missingSrc}
        handleFullScreen={() => {}}
        isFullWidth={true}
        isUsed={false}
      />
    );
  }

  const handleCurrentImage = (current: number) => {
    setCurrentImage(current);
  };

  const handleFullScreen = (index: number) => {
    setIsFullScreen(!isFullScreen);
    handleCurrentImage(index);
  };

  const getImageSize = (index: number) => {
    const isLastIteration = index + 1 === variationImages.length;
    const isSecondIteration = index === 1;
    const isFirstIteration = index === 0;
    const isLastOddNumber = isLastIteration && variationImages.length % 2 !== 0;

    const isFullWidth =
      isFirstIteration || isSecondIteration || isLastOddNumber;

    return isFullWidth;
  };

  const getPreloadSrc = (image: ImageModel) => {
    return isUsed
      ? `${image.src}?preset=product-page-mobile-secondary-used`
      : `${image.src}?preset=product-page-mobile-secondary`;
  };

  const getSmallImage = (image: ImageModel) => {
    return isUsed
      ? `${image.src}?preset=product-page-desktop-secondary-used`
      : `${image.src}?preset=product-page-desktop-secondary`;
  };

  const getLargeImage = (image: ImageModel) => {
    return isUsed
      ? `${image.src}?preset=product-page-desktop-main-used`
      : `${image.src}?preset=product-page-desktop-main`;
  };

  const renderFullScreenCarousel = () => {
    return (
      isFullScreen && (
        <FullScreenModal
          showModal={isFullScreen}
          close={() => setIsFullScreen(false)}
        >
          <Carousel
            images={variationImages}
            handleFullScreen={handleFullScreen}
            isFullScreen
            hasCounter
            hasThumbnail={false}
            currentImage={currentImage}
            handleCurrentImage={handleCurrentImage}
            isUsed={isUsed}
          />
        </FullScreenModal>
      )
    );
  };

  if (!variationImages || variationImages.length === 0) return <></>;

  if (isDesktop) {
    return (
      <>
        <ImageContainer>
          {variationImages.map((image, index) => {
            const isFullWidth = getImageSize(index);

            const lowQualitySrc = getPreloadSrc(image);
            const highQualitySrc = isFullWidth
              ? getLargeImage(image)
              : getSmallImage(image);

            return (
              <BlurredImage
                key={index}
                index={index}
                lowQualitySrc={lowQualitySrc}
                highQualitySrc={highQualitySrc}
                handleFullScreen={handleFullScreen}
                isFullWidth={isFullWidth}
                isUsed={isUsed}
              />
            );
          })}
        </ImageContainer>
        {renderFullScreenCarousel()}
      </>
    );
  }

  return (
    <CarouselContainer>
      <Carousel
        images={variationImages}
        handleFullScreen={handleFullScreen}
        currentImage={currentImage}
        hasThumbnail={true}
        handleCurrentImage={handleCurrentImage}
        isUsed={isUsed}
      />
      {renderFullScreenCarousel()}
    </CarouselContainer>
  );
}

const ImageContainer = styled('div', {
  maxW: '752px',
  display: 'flex',
  flexWrap: 'wrap',
  g: 2,
});

const CarouselContainer = styled('div', {});

export default ProductImage;
