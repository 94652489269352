import ProductTechnicalDetailsModel from 'Models/Pages/ProductPage/ProductTechnicalDetailsModel.interface';
import CollapsableSection from 'Molecules/CollapsableSection/CollapsableSection';
import { isEmpty } from 'Shared/Common/Helpers';
import { styled } from 'stitches.config';
import { ProductType } from '../../Enums/ProductType.enum';
import ProductTranslations from '../../Models/Translations/ProductTranslations.interface';

type ProductTechnicalType = {
  technicalData: ProductTechnicalDetailsModel;
  productLabels: ProductTranslations;
  productType: string;
  odometer: string;
  odometerUnit: string;
  isUsed: boolean;
};

function ProductTechnical({
  technicalData,
  productLabels,
  productType,
  odometer,
  odometerUnit,
  isUsed,
}: ProductTechnicalType) {
  const hasNoTechnicalDetails =
    isEmpty(technicalData) ||
    (isEmpty(technicalData.chassiDetails) &&
      isEmpty(technicalData.engineDetails));

  if (hasNoTechnicalDetails) return <></>;

  const { chassiDetails, engineDetails } = technicalData;

  const isProductMotorcycle = productType === ProductType.MOTORCYCLES;

  if (!isProductMotorcycle) return <></>;

  return (
    <CollapsableSection title={productLabels.technicalDetails} fullWidth>
      <TechnicalWrapper>
        {engineDetails.length > 0 && (
          <>
            <TechnicalSubtitle>{productLabels.engine}</TechnicalSubtitle>
            {isUsed && (
              <TechnicalItem>
                <span>{productLabels.odometer}: </span>
                <TechnicalItemValue>
                  {odometer} {odometerUnit}
                </TechnicalItemValue>
              </TechnicalItem>
            )}
            {engineDetails.map((item, index) => (
              <TechnicalItem key={item.value + index}>
                <span>
                  {
                    productLabels[
                      item.translationKey as keyof ProductTranslations
                    ]
                  }
                  :
                </span>
                <TechnicalItemValue>{item.value}</TechnicalItemValue>
              </TechnicalItem>
            ))}
          </>
        )}
        {chassiDetails.length > 0 && (
          <>
            <TechnicalSubtitle css={{ mt: 4 }}>
              {productLabels.chassi}
            </TechnicalSubtitle>
            {chassiDetails.map((item, index) => (
              <TechnicalItem key={item.value + index}>
                <span>
                  {
                    productLabels[
                      item.translationKey as keyof ProductTranslations
                    ]
                  }
                  :
                </span>
                <TechnicalItemValue>{item.value}</TechnicalItemValue>
              </TechnicalItem>
            ))}
          </>
        )}
      </TechnicalWrapper>
    </CollapsableSection>
  );
}

const TechnicalWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const TechnicalSubtitle = styled('h5', {
  fs: 8,
  wordSpacing: '$wordSpacings$fontPrimary',
  ls: '$ls1',
  fontFamily: '$fontPrimary400',
  mb: 4,
  textTransform: 'uppercase',
});

const TechnicalItem = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
  fs: 8,
  lineHeight: '$lh125',
  mb: 4,
  '&:last-child': {
    mb: 0,
  },
});

const TechnicalItemValue = styled('span', {
  textAlign: 'right',
  pl: 4,
});

export default ProductTechnical;
