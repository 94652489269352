import CollapsableSection from 'Molecules/CollapsableSection/CollapsableSection';
import { styled } from 'stitches.config';
import { DocumentIcon } from 'Atoms/Icons';
import KexLink from 'Kex/KexLink';
import DocumentModel from 'Models/Assets/DocumentModel.interface';
import { isEmpty } from 'Shared/Common/Helpers';
import ProductTranslations from '../../Models/Translations/ProductTranslations.interface';

type ProductDocumentType = {
  documentCollection: DocumentModel[];
  productLabels: ProductTranslations;
};

function ProductDocument({
  documentCollection,
  productLabels,
}: ProductDocumentType) {
  if (isEmpty(documentCollection)) return <></>;

  const getLanguage = (language: string): string => {
    return `${isEmpty(language) ? '' : ` - ${language}`}`;
  };

  return (
    <CollapsableSection title={productLabels.productSheetAndManuals} fullWidth>
      <DocumentWrapper>
        {documentCollection.map((document, index) => {
          const { documentType, url, language } = document;
          return (
            <KexLink
              css={{ mb: '0' }}
              key={index}
              href={url}
              underlined={false}
            >
              <Document>
                <DocumentIcon space={true} size={'m'} color={'primary'} />
                {documentType === 'usermanual' ? (
                  <p>
                    {productLabels.productUserManual}
                    {getLanguage(language)}
                  </p>
                ) : documentType === 'specificationsheet' ? (
                  <p>
                    {productLabels.productDataSheet}
                    {getLanguage(language)}
                  </p>
                ) : (
                  <p>
                    {productLabels.other}
                    {getLanguage(language)}
                  </p>
                )}
              </Document>
            </KexLink>
          );
        })}
      </DocumentWrapper>
    </CollapsableSection>
  );
}

export default ProductDocument;

const DocumentWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
});

const Document = styled('div', {
  fontFamily: '$fontSecondary500',
  fs: 7,
  lineHeight: '$lh125',
  display: 'flex',
  alignItems: 'center',
});
