import { styled } from 'stitches.config';
import { useVariantData } from 'Shared/Providers/VariantProvider';
import VariantChips from 'Atoms/Chips/VariantChips';
import VariationModel from 'Models/KexVariation/VariationModel.interface';
import { Variant } from '../../Enums/Variant.enum';
import { useCallback, useEffect, useMemo } from 'react';
import SmallLabel from 'Atoms/Typography/SmallLabel/SmallLabel';

type ProductVariationPickerType = {
  variationCollection: VariationModel[];
  colorLabel: string;
  sizeLabel: string;
  productType: string;
  isUsed: boolean;
};

function ProductVariationPicker({
  variationCollection,
  colorLabel,
  sizeLabel,
  isUsed,
}: ProductVariationPickerType) {
  const { state, variantDispatch } = useVariantData();

  const uniqueColors = useMemo(() => {
    return variationCollection?.filter((variation, index) => {
      return (
        variationCollection
          ?.map((item) => item?.color?.key)
          ?.indexOf(variation?.color?.key) === index
      );
    });
  }, [variationCollection]);

  const uniqueSizes = useMemo(() => {
    return Array.from(
      new Set(variationCollection.map((variation) => variation.size.key))
    );
  }, [variationCollection]);

  const findVariationBySize = useCallback(
    (size: string) => {
      const sizes = variationCollection.filter((variation) => {
        return variation.size.key === size;
      });

      const matchingWithColor = sizes.find(
        (variation) => variation.color.key === state.selectedVariant?.color?.key
      );

      return matchingWithColor || sizes[0];
    },
    [state.selectedVariant?.color?.key, variationCollection]
  );

  const findSelectedByCode = useCallback(
    (variantCode: string) => {
      return variationCollection.find(
        (variation) => variation.code === variantCode
      );
    },
    [variationCollection]
  );

  const handleColorChange = (variantCode: string) => {
    const foundSelected = findSelectedByCode(variantCode);
    if (!foundSelected) return;

    const hasNotClickedSameColor =
      foundSelected.color.key !== state.selectedVariant?.color?.key;

    if (hasNotClickedSameColor) {
      variantDispatch({
        type: Variant.SET_VARIANT,
        selectedVariant: foundSelected,
        hasSelectedSize: false,
      });
    }
  };

  const handleSizeChange = useCallback(
    (variantCode: string) => {
      const foundSelected = findSelectedByCode(variantCode);
      if (!foundSelected) return;

      variantDispatch({
        type: Variant.SET_VARIANT,
        selectedVariant: foundSelected,
        hasSelectedSize: true,
      });
    },
    [findSelectedByCode, variantDispatch]
  );

  //if only one size select the first as default
  useEffect(() => {
    if (uniqueSizes.length === 1)
      handleSizeChange(findVariationBySize(uniqueSizes[0]).code);
  }, [findVariationBySize, handleSizeChange, uniqueColors, uniqueSizes]);

  return (
    <>
      {state.hasColors && !isUsed && (
        <>
          <SmallLabel>{colorLabel}</SmallLabel>
          <ColorVariationsWrapper>
            {uniqueColors?.map((variation, index) => (
              <VariantChips
                key={index}
                text={variation?.color?.name}
                color={variation?.color?.key}
                isChecked={
                  variation?.color?.key === state.selectedVariant?.color?.key
                }
                onCheck={() => handleColorChange(variation?.code)}
              />
            ))}
          </ColorVariationsWrapper>
        </>
      )}

      {state.hasSizes && (
        <>
          <SmallLabel>{sizeLabel}</SmallLabel>
          <SizesVariationsWrapper>
            {state.selectedVariant?.size &&
              uniqueSizes.map((size, index) => {
                const variation = findVariationBySize(size);

                const notMatchingSizeForCurrentColor =
                  state.selectedVariant?.color?.key !== variation.color.key;

                return (
                  <VariantChips
                    key={index}
                    text={variation.size.name}
                    size={variation.size.key}
                    isChecked={
                      variation.size.key === state.selectedVariant?.size?.key &&
                      state.hasSelectedSize
                    }
                    onCheck={() => handleSizeChange(variation?.code)}
                    disabled={
                      notMatchingSizeForCurrentColor ||
                      (!variation.isBuyable &&
                        variation.stock.salesStartFormatted === '')
                    }
                  />
                );
              })}
          </SizesVariationsWrapper>
        </>
      )}
    </>
  );
}

const VariationsWrapper = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: 16,
};

const ColorVariationsWrapper = styled('div', {
  ...VariationsWrapper,
  mb: 8,
});

const SizesVariationsWrapper = styled('div', {
  ...VariationsWrapper,
  mb: 16,
});

export default ProductVariationPicker;
