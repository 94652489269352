import ProductTranslations from 'Models/Translations/ProductTranslations.interface';
import CollapsableSection from 'Molecules/CollapsableSection/CollapsableSection';
import { isEmpty } from 'Shared/Common/Helpers';
import { styled } from 'stitches.config';

type PropTypes = {
  fitsToModel: string[];
  productLabels: ProductTranslations;
};

const ProductFitmentBikes = ({ fitsToModel, productLabels }: PropTypes) => {
  if (isEmpty(fitsToModel)) return <></>;

  return (
    <>
      <CollapsableSection title={productLabels.productFitmentModels} fullWidth>
        <div>
          {fitsToModel.map((model, i) => (
            <FitmentModel key={model + i}>{model}</FitmentModel>
          ))}
        </div>
      </CollapsableSection>
    </>
  );
};

const FitmentModel = styled('div', {
  display: 'block',
  lineHeight: '$lh155',
});

export default ProductFitmentBikes;
