import { styled } from 'stitches.config';
import BulletPoint from 'Atoms/BulletPoint/BulletPoint';
import { ReturnIcon, TruckIcon, WrenchIcon } from 'Atoms/Icons';
import { ProductType } from '../../Enums/ProductType.enum';
import ProductTranslations from '../../Models/Translations/ProductTranslations.interface';
import { useState } from 'react';
import TradeInModal from './Modal/TradeInModal';

type ProductUSPType = {
  usp: string[];
  productType: string;
};

type ProductUSPSecondaryType = {
  productLabels: ProductTranslations;
  productType: string;
  isUsed: boolean;
};

export function ProductUSP({ usp, productType }: ProductUSPType) {
  const isClothing = productType === ProductType.CLOTHING;

  if (!usp || usp.length === 0 || isClothing) {
    return <></>;
  }

  return (
    <USPWrapper>
      {usp.slice(0, 3).map((text, index) => (
        <BulletPoint
          css={CSSBulletPoint}
          key={text + index}
          text={text}
          backgroundColor="secondaryDark"
        ></BulletPoint>
      ))}
    </USPWrapper>
  );
}

export function ProductUSPSecondary({
  productLabels,
  productType,
  isUsed,
}: ProductUSPSecondaryType) {
  const [showTradeInModal, setShowTradeInModal] = useState<boolean>(false);

  const {
    customizeYourMotorcycleInConfigurator,
    doYouWantTradeInYourMotorcycle,
    canBeDeliveredWithin,
  } = productLabels;

  if (productType !== ProductType.MOTORCYCLES) {
    return <></>;
  }

  return (
    <>
      <USPSecondaryWrapper isUsed={isUsed}>
        {!isUsed && (
          <USPItem>
            <WrenchIcon color="primary" size="m" />
            {customizeYourMotorcycleInConfigurator}
          </USPItem>
        )}
        <USPItem>
          <TruckIcon color="primary" size="m" />
          {canBeDeliveredWithin}
        </USPItem>
        <USPItem>
          <ReturnIcon color="primary" size="m" />
          <TradeInMotorcycle
            css={{ mb: 0, textDecoration: 'underline' }}
            onClick={() => setShowTradeInModal(true)}
          >
            {doYouWantTradeInYourMotorcycle}
          </TradeInMotorcycle>
        </USPItem>
      </USPSecondaryWrapper>

      <TradeInModal
        showModal={showTradeInModal}
        setShowModal={setShowTradeInModal}
      />
    </>
  );
}

const USPWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  color: '$textPrimary',
  fs: 8,
  lineHeight: '$lh125',
  gap: 16,
  mb: 8,
});

const USPSecondaryWrapper = styled('div', {
  mb: 12,
  variants: {
    isUsed: {
      true: {
        mt: 12,
      },
    },
  },
});

const TradeInMotorcycle = styled('button', {
  textAlign: 'left',
  fs: 7,
  fontFamily: '$fontPrimary400',
  wordSpacing: '$wordSpacings$fontPrimary',
  ls: '$lsn1',
  lineHeight: '$lh171',
  textUnderlineOffset: '4px',
  '@mediaMinMedium': {
    fs: 9,
    lineHeight: '$lh133',
  },
});

const USPItem = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  color: '$textPrimary',
  fs: 7,
  lineHeight: '$lh171',
  gap: 16,
  mb: 6,
  fontFamily: '$fontPrimary400',
  ls: '$lsn1',
  wordSpacing: '$wordSpacings$fontPrimary',
  '&:last-child': {
    mb: 0,
  },
  width: '100%',
  '@mediaMinMedium': {
    fs: 9,
    lineHeight: '$lh133',
  },
});

const CSSBulletPoint = {
  fs: 8,
};
