import StockViewer from '../../Atoms/StockViewer/StockViewer';
import ProductPageModel from 'Models/Pages/ProductPage/ProductPageModel.interface';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'stitches.config';
import ProductDescription from './ProductDescription';
import { ProductUSP, ProductUSPSecondary } from './ProductUSP';
import ProductCTA from './ProductCTA';
import ProductVariationPicker from './ProductVariationPicker';
import ProductPricing from './ProductPricing';
import ProductTechnical from './ProductTechnical';
import ProductDocument from './ProductDocument';
import ProductTitle from './ProductTitle';
import { isEmpty } from 'Shared/Common/Helpers';
import { useVariantData } from 'Shared/Providers/VariantProvider';
import Paragraph from 'Atoms/Typography/Paragraph/Paragraph';
import { Variant } from '../../Enums/Variant.enum';
import { useEffect } from 'react';
import VariationModel from 'Models/KexVariation/VariationModel.interface';
import ProductPackage from './ProductPackage';
import FavoritesSection from './FavoritesSection';
import ProductFitmentBikes from './ProductFitmentBikes';
import KexLink from 'Kex/KexLink';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import { animation, timings } from 'Theme/Settings/animation';
import { ArrowIcon } from 'Atoms/Icons';
import { ENUM_KTM } from 'Shared/Constants/brand';
import { ProductType } from 'Enums/ProductType.enum';

type PropTypes = {
  productPage: ProductPageModel;
};

function AttributeArea({ productPage }: PropTypes) {
  const {
    productLabels: {
      color: colorLabel,
      size: sizeLabel,
      used,
      detailedDescription,
      packageIncludes,
      variationCode,
    },
    productLabels,
    explodedViews,
  } = useTranslationData();

  const { staticPages } = useAppSettingsData();

  const {
    extendedDescription,
    productTechnicalDetails,
    usp,
    isUsed,
    productType,
    paymentInfoBody,
    documentCollection,
    variationCollection,
    packageItems,
    isPackage,
    fitsToModel,
    odometer,
    odometerUnit,
    brand,
  } = productPage;

  const { variantDispatch, state } = useVariantData();

  useEffect(() => {
    if (isEmpty(variationCollection)) return;

    const fallbackVariant = variationCollection[0];
    // get variant that is buyable, and is not price 0 and is the lowest price

    const defaultVariant = variationCollection
      .filter((variant) => variant.nowPrice.price !== 0 && variant.isBuyable)
      .reduce<null | VariationModel>((prev, curr) => {
        if (prev === null) return curr;

        return prev.nowPrice.price < curr.nowPrice.price ? prev : curr;
      }, null);

    const hasColors = variationCollection.some(
      (variation) => !isEmpty(variation?.color)
    );

    const hasSizes = variationCollection.some(
      (variation) => !isEmpty(variation?.size)
    );

    variantDispatch({
      type: Variant.SET_INITIAL,
      selectedVariant: defaultVariant ?? fallbackVariant,
      hasColors: hasColors,
      hasSizes: hasSizes,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variationCollection]);

  const hasExplodedViewLink =
    brand === ENUM_KTM &&
    (productType === ProductType.SPAREPARTS ||
      productType === ProductType.ACCESSORIES);

  return (
    <AttributeContainer>
      <AttributeWrapper>
        <ProductTitle used={used} productPage={productPage} />
        <VariantCodeWrapper>
          {state.selectedVariant &&
            (state.hasSizes ? state.hasSelectedSize : true) &&
            !isEmpty(state.selectedVariant?.retailerArticleNumber) && (
              <Paragraph size="xs">
                {`${variationCode}: ${state.selectedVariant?.retailerArticleNumber}`}
              </Paragraph>
            )}
        </VariantCodeWrapper>
        <ProductUSP usp={usp} productType={productType} />
        <ProductVariationPicker
          variationCollection={variationCollection}
          colorLabel={colorLabel}
          sizeLabel={sizeLabel}
          productType={productType}
          isUsed={isUsed}
        />
        <Container>
          <ProductPricing
            paymentInfoBody={paymentInfoBody}
            productType={productType}
          />
          <FavoritesSection />
        </Container>
        <ProductCTA
          variationCollection={variationCollection}
          isUsed={isUsed}
          productLabels={productLabels}
          productType={productType}
          productCode={productPage.code}
        />
        <StockViewer productLabels={productLabels} isUsed={isUsed} />
        <ProductPackage
          packageItems={packageItems}
          isPackage={isPackage}
          packageIncludes={packageIncludes}
        />

        <ProductUSPSecondary
          productLabels={productLabels}
          productType={productType}
          isUsed={isUsed}
        />
      </AttributeWrapper>

      <ProductDescription
        extendedDescription={extendedDescription}
        descriptionTitle={detailedDescription}
      />
      <ProductTechnical
        technicalData={productTechnicalDetails}
        productLabels={productLabels}
        productType={productType}
        odometer={odometer}
        odometerUnit={odometerUnit}
        isUsed={isUsed}
      />
      <ProductDocument
        documentCollection={documentCollection}
        productLabels={productLabels}
      />
      <ProductFitmentBikes
        fitsToModel={fitsToModel}
        productLabels={productLabels}
      />
      {hasExplodedViewLink && (
        <ExplodedViewLink
          href={staticPages.explodedViewsPage}
          fontFamily="primary500"
          underlined={false}
        >
          {explodedViews.explodedViewsLink}
          <ArrowIcon color="secondary" size="m" rotateLeft />
        </ExplodedViewLink>
      )}
    </AttributeContainer>
  );
}

const AttributeContainer = styled('div', {});

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const AttributeWrapper = styled('div', {
  w: '100%',
  px: 4,
  '@mediaMinLarge': {
    px: 0,
  },
});

const ExplodedViewLink = styled(KexLink, {
  color: '$collapsableText',
  display: 'flex',
  p: 4,
  justifyContent: 'space-between',
  backgroundColor: '$collapsableHeaderBackground',
  alignItems: 'center',
  cursor: 'pointer',
  fs: 9,
  lh: '$lh133',
  transition: `all ${timings.threeTenths} ${animation.timingFn}`,
  textTransform: 'capitalize!important',
  '@mediaMinLarge': {
    '&:hover': {
      backgroundColor: '$collapsableHoverBackground',
    },
  },
  letterSpacing: '$lsn1 !important',
});

const VariantCodeWrapper = styled('div', {
  mb: 8,
});

export default AttributeArea;
