import { styled } from 'stitches.config';
import { useVariantData } from 'Shared/Providers/VariantProvider';
import { useUserStateData } from 'Shared/Providers/UserContextProvider';
import {
  AddFavorite,
  GetFavorites,
  RemoveFavorite,
} from 'Shared/Favorites/Favorites';
import FavoriteButton from 'Atoms/Buttons/FavoriteButton';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';

function FavoritesSection() {
  const { state } = useVariantData();
  const {
    user: { authenticated },
    accountDispatch,
  } = useUserStateData();

  const { languageRoute } = useAppSettingsData();
  const { favoriteList } = GetFavorites(languageRoute);
  const variant = state.selectedVariant;
  if (!variant) return <></>;

  const isFavorited = (): boolean => {
    return favoriteList?.favoriteProducts?.some(
      (x) => x.code === variant?.code
    );
  };

  const onFavoriteClick = () => {
    if (authenticated) {
      isFavorited()
        ? RemoveFavorite(variant ? variant?.code : '', languageRoute)
        : AddFavorite(variant ? variant?.code : '', languageRoute);
      return;
    }
    accountDispatch({ type: 'toggle' });
  };
  return (
    <PriceContainer>
      <FavoriteContainer>
        <FavoriteButton
          size={'m'}
          isActive={isFavorited()}
          onClick={() => {
            if (state.hasSizes && !state.hasSelectedSize) return;

            onFavoriteClick();
          }}
          disabled={state.hasSizes && !state.hasSelectedSize}
        />
      </FavoriteContainer>
    </PriceContainer>
  );
}

const FavoriteContainer = styled('div', {});

const PriceContainer = styled('div', { mb: 8 });

export default FavoritesSection;
