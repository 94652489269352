import ProductPageModel from 'Models/Pages/ProductPage/ProductPageModel.interface';
import ContentContainer from 'Molecules/ContentContainer/ContentContainer';
import ContentArea from 'Organisms/ContentArea/ContentArea';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { styled } from 'stitches.config';
import AttributeArea from './AttributeArea';
import ProductImage from './ProductImage';
import { VariantProvider } from 'Shared/Providers/VariantProvider';
import ProductListingBlock from '../../Organisms/Blocks/ProductListingBlock';
import Grid from 'Atoms/Grids/Grid';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import useMedia from 'Shared/Hooks/useMedia';
import ProductTag from 'Atoms/ProductTag/ProductTag';

function ProductPage() {
  const { productLabels } = useTranslationData();
  const productData = useCurrentPage<ProductPageModel>();
  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);

  const { contentArea, relatedProducts, similarProducts } = productData;

  return (
    <VariantProvider>
      <ContentContainer noPadding={!isDesktop}>
        <Top>
          <ProductImageWrapper>
            <ProductTag
              text={productData.promotion?.promotionName}
              css={{ left: isDesktop ? -8 : 8 }}
            />
            <ProductImage />
          </ProductImageWrapper>
          <AttributeArea productPage={productData} />
        </Top>
      </ContentContainer>

      {contentArea && contentArea.length > 0 && (
        <Section css={ContentSection}>
          <Bottom>
            {Array.isArray(contentArea) && (
              <ContentArea childItems={contentArea} />
            )}
          </Bottom>
        </Section>
      )}
      <BottomContainer>
        <Grid>
          {relatedProducts?.length > 0 && (
            <ProductListingBlock
              content={{
                productCards: relatedProducts,
                header: productLabels.relatedProducts,
                inEditMode: false,
                link: [],
              }}
              noPadding={false}
            />
          )}

          {similarProducts?.length > 0 && (
            <ProductListingBlock
              content={{
                productCards: similarProducts,
                header: productLabels.similarProducts,
                inEditMode: false,
                link: [],
              }}
              noPadding={false}
            />
          )}
        </Grid>
      </BottomContainer>
    </VariantProvider>
  );
}

const Section = styled('section', {
  backgroundColor: '$productPageBackgroundPrimary',
  position: 'relative',
  maxW: '100%',
  mb: 20,
  pb: 20,
});

const BottomContainer = styled('div', {
  mt: 20,
});

const Top = styled('div', {
  g: 0,
  w: '100%',
  mb: 20,
  '@mediaMinLarge': {
    display: 'grid',
    gridTemplateColumns: '1.3fr 1fr',
    g: 8,
    mb: 48,
  },
});

const ProductImageWrapper = styled('div', {
  position: 'relative',
  px: 0,
  w: '100%',
  mb: 6,
  '@mediaMinLarge': {
    mb: 0,
  },
});

const ContentSection = {
  backgroundColor: '$primary4',
  px: 0,
};

const Bottom = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  pl: 0,
  mt: 36,
  h: 'auto',
  w: '100%',
  backgroundColor: 'primary4',
  borderBlockStart: '10px',
});

export default ProductPage;
