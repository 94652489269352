import ColorOption from 'Atoms/ColorOption/ColorOption';
import { styled } from 'stitches.config';
import { StyledChips } from './StyledChips';

type PropType = {
  isChecked: boolean;
  onCheck: React.MouseEventHandler<HTMLButtonElement>;
  text: string;
  color?: string;
  size?: string;
  disabled?: boolean;
};

function VariantChips({
  isChecked,
  onCheck,
  text,
  color,
  size,
  disabled,
}: PropType) {
  return (
    <VariantChipsWrapper
      onClick={onCheck}
      isChecked={isChecked}
      disabled={disabled}
    >
      {color && (
        <>
          <ColorOption color={color} size="m" />
          {text}
        </>
      )}
      {size && <>{text}</>}
    </VariantChipsWrapper>
  );
}

const VariantChipsWrapper = styled('button', StyledChips);

export default VariantChips;
