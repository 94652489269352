import { styled } from 'stitches.config';
import { useVariantData } from 'Shared/Providers/VariantProvider';
import { ProductType } from 'Enums/ProductType.enum';
import Price from 'Atoms/Price/Price';

import PricePerMonth from 'Atoms/Price/PricePerMonth';

type ProductDocumentType = {
  paymentInfoBody: string;
  productType: string;
};

function ProductPricing({ paymentInfoBody, productType }: ProductDocumentType) {
  const { state } = useVariantData();

  const variant = state.selectedVariant;
  if (!variant) return <></>;

  const isMotorcycle = productType === ProductType.MOTORCYCLES;

  return (
    <PriceContainer>
      {variant && variant.nowPrice.price !== 0 && (
        <>
          <Price
            friendlyWasPrice={variant.wasPrice.priceRoundedAsString}
            friendlyNowPrice={variant.nowPrice.priceRoundedAsString}
            wasPrice={variant.wasPrice.price}
            nowPrice={variant.nowPrice.price}
            size="lg"
          />
          {isMotorcycle &&
            state.selectedVariant &&
            state?.selectedVariant?.paymentMonthlyCost?.price > 0 && (
              <MonthlyPrice>
                <PricePerMonth
                  friendlyMonthlyPrice={
                    state?.selectedVariant?.paymentMonthlyCost
                      ?.priceRoundedAsString
                  }
                />

                {/* <Tooltip text={paymentInfoBody} /> */}
                {paymentInfoBody}
              </MonthlyPrice>
            )}
        </>
      )}
    </PriceContainer>
  );
}

const MonthlyPrice = styled('div', {
  mt: 2,
  fs: 6,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  lineHeight: '$lh133',
  fontFamily: '$fontSecondary400',
  color: '$primaryLight3 !important',
  maxWidth: 450,
});

const PriceContainer = styled('div', { mb: 8 });

export default ProductPricing;
