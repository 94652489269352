import { styled } from 'stitches.config';
import { H2 } from 'Atoms/Typography/Headings/Heading';
import Paragraph from 'Atoms/Typography/Paragraph/Paragraph';
import Modal from 'Organisms/Modal/Modal';
import { useTranslationData } from 'Shared/Providers/TranslationProvider';
import { ChangeEvent, useEffect, useRef } from 'react';
import Input from 'Atoms/Input/Input';
import { InputValidation } from 'Atoms/Input/InputValidation';
import { useState } from 'react';
import { accountFormValidation } from 'Shared/Common/AccountFormValidation';
import Chips from 'Atoms/Chips/Chips';
import Button from 'Atoms/Buttons/Button';
import { useAppSettingsData } from 'Shared/Providers/AppSettingsProvider';
import KexIconLoader from 'Kex/KexIconLoader';
import CloseButton from 'Atoms/Buttons/CloseButton';
import BulletPoint from 'Atoms/BulletPoint/BulletPoint';
import CircleBackground from 'Atoms/Icons/CircleBackground';
import FormData from 'form-data';
import { useUserStateData } from 'Shared/Providers/UserContextProvider';

const CheckIcon = KexIconLoader('Checkmark');

type PropTypes = {
  showModal: boolean;
  setShowModal: (arg: boolean) => void;
};

const API_URL = '/Email/TradeIn';
const ACCEPTED_FILE_TYPES = '.png, .jpeg, .jpg';

const TradeInModal = ({ showModal, setShowModal }: PropTypes) => {
  const [formCondition, setCondition] = useState<string>('');
  const [formImages, setImages] = useState<any>([]);
  const [inputsValid, setInputsValid] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const {
    productLabels: {
      tradeInYourMotorcycle,
      howToTradeInYourMotorcycle,
      yourDetailsMotorcycle,
      registrationNumber,
      mileage,
      conditionMotorcycle,
      ok,
      good,
      veryGood,
      uploadYourImages,
      upload,
      doneUploading,
      sendTradeInRequest,
    },
    commonLabels: {
      contactDetails,
      fullName,
      somethingWentWrong,
      messageSent,
      thanksForYourMessage,
    },
    validationLabels: { invalidEmail, isRequired },
    accountLabels: { email, phoneNumber },
  } = useTranslationData();

  const { languageRoute } = useAppSettingsData();
  const { user } = useUserStateData();

  const timerRef = useRef<NodeJS.Timeout>();

  const [status, setStatus] = useState<
    'init' | 'loading' | 'error' | 'success'
  >('init');

  useEffect(() => {
    if (showModal) setStatus((s) => (s === 'success' ? 'init' : s));
  }, [showModal]);

  useEffect(() => {
    if (status !== 'success') return;

    timerRef.current = setTimeout(() => {
      setCondition('');
      setInputsValid(false);
      setImages([]);
      setShowModal(false);
    }, 3000);

    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, [status, timerRef, setCondition, setInputsValid, setImages, setShowModal]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target as HTMLInputElement;

    if (!input.files) return;

    setImages([...formImages, ...Array.from(input.files)]);
  };

  const onInputsValid = () => {
    setInputsValid(true);
  };

  const onInputsInvalid = () => {
    setInputsValid(false);
  };

  const isFormValid =
    inputsValid && formImages.length > 0 && formCondition !== '';

  async function submitForm(): Promise<void> {
    try {
      setStatus('loading');
      const form = new FormData();

      const formContainer = document.querySelector(
        '#trade-in-form'
      ) as HTMLFormElement;
      const formData = new FormData(formContainer) as any;

      for (const value of formData.entries()) {
        form.append(value[0], value[1]);
      }

      form.append('ratedCondition', formCondition);
      form.append('language', languageRoute);

      const obj = await fetch(API_URL, {
        method: 'POST',
        body: form as any,
      }).then((res) => res.json());

      setStatus(obj?.success ? 'success' : 'error');
    } catch (error) {
      console.error(error);
      setStatus('error');
    }
  }

  if (status === 'success') {
    return (
      <Modal showModal={showModal} toggle={() => setShowModal(false)}>
        <H2 css={{ mb: 12 }}>{messageSent}</H2>
        <BulletPoint text={thanksForYourMessage} backgroundColor="primary" />
      </Modal>
    );
  }

  return (
    <Modal showModal={showModal} toggle={() => setShowModal(!showModal)}>
      <H2 css={TradeInTitle}>{tradeInYourMotorcycle}</H2>
      <Paragraph css={HowToTradeIn}>{howToTradeInYourMotorcycle}</Paragraph>

      <TradeInForm onSubmit={(e) => e.preventDefault()} id="trade-in-form">
        <InputValidation
          onInputsValid={onInputsValid}
          onInputsInvalid={onInputsInvalid}
        >
          <FormGroupTitle>{yourDetailsMotorcycle}</FormGroupTitle>
          <Details>
            <Input
              title={registrationNumber}
              placeholder={registrationNumber}
              name="registrationNumber"
              type="text"
              validation={{
                required: true,
                errorMessage: isRequired.replace('{0}', registrationNumber),
              }}
            />
            <Input
              title={mileage}
              placeholder={mileage}
              name="mileage"
              type="text"
              validation={{
                required: true,
                errorMessage: isRequired.replace('{0}', mileage),
              }}
            />
          </Details>

          <FormGroupTitle>{conditionMotorcycle}</FormGroupTitle>
          <Condition>
            <Chips
              onCheck={() => setCondition(ok)}
              text={ok.toUpperCase()}
              isChecked={formCondition === ok}
            />
            <Chips
              onCheck={() => setCondition(good)}
              text={good.toUpperCase()}
              isChecked={formCondition === good}
            />
            <Chips
              onCheck={() => setCondition(veryGood)}
              text={veryGood.toUpperCase()}
              isChecked={formCondition === veryGood}
            />
          </Condition>

          <Contact>
            <FormGroupTitle>{contactDetails}</FormGroupTitle>
            <Input
              title={fullName}
              placeholder={fullName}
              name="fullName"
              type="text"
              autoComplete="given-name"
              validation={{
                required: true,
                errorMessage: isRequired.replace('{0}', fullName),
              }}
              defaultValue={`${user.firstName} ${user.lastName}`}
            />
            <Input
              title={phoneNumber}
              placeholder={phoneNumber}
              name="phone"
              type="text"
              autoComplete="tel"
              validation={{
                required: true,
                errorMessage: isRequired.replace('{0}', phoneNumber),
              }}
              defaultValue={user.phoneNumber}
            />
            <Input
              title={email}
              placeholder={email}
              name="email"
              type="text"
              validation={{
                ...accountFormValidation.email,
                errorMessage: invalidEmail,
              }}
              autoComplete="email"
              defaultValue={user.email}
            />
          </Contact>

          <FormGroupTitle>{uploadYourImages}</FormGroupTitle>
          <Images>
            <Button
              type="secondary"
              icon={{ type: 'Share', color: 'secondaryDark', size: 'm' }}
              fullWidth
              onClick={() => {
                inputRef.current?.click();
              }}
            >
              {upload}
            </Button>

            {formImages.length > 0 && (
              <UploadedAmount>
                <CircleBackground
                  backgroundColorVariants="primary"
                  css={{ mt: 0 }}
                >
                  <CheckIcon size="xs" color="secondaryDark" />
                </CircleBackground>
                {formImages.length} {doneUploading}
                <CloseButton
                  onClick={() => setImages([])}
                  bgColor="primary"
                  size="s"
                  css={{ alignSelf: 'center' }}
                ></CloseButton>
              </UploadedAmount>
            )}

            <input
              style={{ display: 'none' }}
              ref={inputRef}
              type="file"
              onChange={handleFileChange}
              name="image"
              accept={ACCEPTED_FILE_TYPES}
              multiple
            />
          </Images>

          {status === 'error' && <Error>{somethingWentWrong}</Error>}

          {isFormValid && (
            <Button onClick={submitForm} fullWidth>
              {sendTradeInRequest}
            </Button>
          )}
        </InputValidation>
      </TradeInForm>
    </Modal>
  );
};

const TradeInForm = styled('form', {});

const FormGroupTitle = styled('span', {
  fs: 9,
  fontFamily: '$fontSecondary400',
  lineHeight: '$lh155',
  mb: 2,
  display: 'block',
});

const Details = styled('div', {
  mb: 7,
});

const Condition = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: 16,
  mb: 12,
  pt: 2,
});

const Contact = styled('div', {
  mb: 7,
});

const Images = styled('div', {
  mb: 7,
  pt: 2,
});

const UploadedAmount = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: 8,
  mt: 4,
});

const Error = styled('div', {
  mb: 2,
});

const HowToTradeIn = {
  mb: 6,
};

const TradeInTitle = {
  mb: 6,
  width: 'auto',
  '@mediaMinLarge': {
    width: '80%',
  },
};
export default TradeInModal;
